<template>
    <transition name="fade" v-if="loading">
        <loading-component></loading-component>
    </transition>

    <transition v-if="!loading" name="fade">
        <div id="wrapper" class="bg-white pt-4">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xl-8 offset-xl-2 col-lg-8 col-md-8 offset-lg-2 offset-md-2">

                        <div class="d-flex text-center">
                            <div class="text-left p-4 pb-2">
                                <font-awesome-icon class="text-danger display-2" :icon="['fa', 'user-shield']"></font-awesome-icon>

                                <h3 class="text-uppercase text-danger fw-bold mb-1 mt-4 mt-2" style="letter-spacing: 1px">Verification Needed</h3>
                                
                                <p class="fs-5 mt-4">We're currently vetting your information. We'll let you know if your account as a driver gets verified. </p>                                
                            </div>
                        </div>

                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12">
                                    <status v-model:status_data=status_data></status>
                                </div>
                            </div>
                        </div>                                    
                    </div>  
                </div>
            </div>
        </div>
    </transition>
</template>

<style scoped lang="scss">
    @import '../assets/scss/base/_base.scss';
    @import '../assets/scss/base/_fonts.scss';
    @import '../assets/scss/helpers/breakpoints.scss';

    .card {
        box-shadow: 0 4px 8px rgb(0 0 0 / 3%)
    }

    .input-group {
        .input-group-text {
            background: #eee;
        }

        .form-control {
            background: #eee;
        }
    }  
</style>

<script>
    import Status from '../components/Status';
    import LoadingComponent from '../components/Loading'

    export default {
        name: 'VerificationNeeded',
        components: {
            Status,
            LoadingComponent
        },
        data() {
            return {
                data: {
                    email: '',
                    password: '',
                },
                status_data: {
                    "success": false,
                    "success_message": null, 
                    "error": false,
                    "error_message": null
                },
                errors: [],
                eye: 'eye'
            }
        },
        watch: {
            user: function() {
                this.ifNotLoggedIn()
            }
        },
        computed: {
            user () {
                return this.$store.state.user
            },
            loading () {
                return this.$store.state.loading
            }                   
        },    
        mounted() {
            this.$store.dispatch("getDriver")
        },
        methods: {
            ifNotLoggedIn() {
                if( !this.user.name) {
                    localStorage.removeItem('user')
                    this.$router.push({name: 'Login'});
                } else {
                    if( !this.user.driver_verified) {
                        this.$store.dispatch("changeLoading", false)
                    } else {
                        this.$router.push({name: 'DriverHome'});
                    }
                } 
            }
        }
    }
</script>