<template>
    <div class="container">
        <div class="center">
            <div class="lds-ripple">
                <div></div>
                <div></div>
            </div>
        </div>
    </div>  
</template>

<style lang="scss" scoped>
    @import '../assets/scss/layout/loading/_spinner.scss';
</style>

<script>
export default {
    name: 'Loading'
}
</script>